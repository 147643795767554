import React, { useEffect, useState, useContext } from 'react';
import { get } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import useFilteredData from '../../hooks/useFilteredData';
import { UserInfoContext } from '../../contexts';
import { exportTableData } from '../../utility/exportToExcel';
import SelectInput from '../inputs/SelectInput';
import {
  formatTableHeaderToCamelCase,
  formatTableNameFromCamelCase
} from '../../utility/general';

const ColumnHeaders = ['Module', 'MDA Name', 'Approved', 'Declined', 'Pending'];

let reportSummaryMemo;

const ReportSummary = ({ history }) => {
  const [title, setTitle] = useState('');
  const { setLoading } = useContext(UserInfoContext);
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [reportSummaryData, setReportSummary] = useState([]);
  const exportData = useFilteredData(reportSummaryData);

  const getonBoardingStaffData = async () => {
    try {
      setLoading(true);
      setReportSummary([])
      const { status, data } = await get('/Report/Summary', {}, true);
      if (status === 'success') {
        reportSummaryMemo = data;
        setReportSummary(data);
      }
    } catch (error) {
      console.log(error);
      setReportSummary([])
    } finally {
      setLoading(false);
    }
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Report Summary';
    setTitle('Report Summary');
    getonBoardingStaffData();
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  const onInputChange = async (value) => {
    try {
      setLoading(true);
      if (value === 'All') return setReportSummary(reportSummaryMemo);
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];

      const { status, data } = await get(`/Report/Summary/${mda.id}`, {}, true);
      if (status === 'success') {
        setReportSummary(data);
      }
    } catch (error) {
      console.log(error);
      setReportSummary([])
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders, "Report Summary")}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {ColumnHeaders.map((header) => (
              <div className="th ">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-body">
          {exportData.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => (
                <div className="td left-text">{item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportSummary;
