/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import { RiBarChartFill, RiUserSettingsLine } from 'react-icons/ri';
import { BiEnvelope } from 'react-icons/bi';
import { BsCardList, BsListCheck } from 'react-icons/bs';
import { HiOutlineUserAdd } from 'react-icons/hi';
import { GoRepoClone } from 'react-icons/go';

export default [
  {
    title: 'MDA Log',
    href: '/report_portal/mdas',
    icon: <BiEnvelope className="icon" />
  },
  {
    title: 'Module Usage',
    href: '/report_portal',
    icon: <RiBarChartFill className="icon" />
  },

  {
    title: 'Onboarded Staff',
    href: '/report_portal/onboarded_staff',
    icon: <HiOutlineUserAdd className="icon" />
  },
  {
    title: 'Staff Information',
    href: '/report_portal/staff',
    icon: <RiUserSettingsLine className="icon" />
  },
  {
    title: 'User Request Analytics',
    href: '/report_portal/requests',
    icon: <BsCardList className="icon" />
  },
  {
    title: 'Reports',
    href: '/report_portal/report',
    icon: <GoRepoClone className="icon" />
  },
  {
    title: 'Report Details',
    href: '/report_portal/report_details',
    icon: <GoRepoClone className="icon" />
  },
  {
    title: 'Approvals Analytics',
    href: '/report_portal/approvals',
    icon: <BsListCheck className="icon" />
  }
];
