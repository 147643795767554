import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loader from './components/loader';

import { UserInfoContext } from './contexts';

import Homepage from './components/home';
import ProtectedRoute from './routes/ProtectedRoute';
import UserPortal from './components/UserPortal';

function App() {
  const history = createBrowserHistory();
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userInfo = localStorage.getItem('USER_INFO');
    if (userInfo) {
      setCurrentUser(JSON.parse(userInfo));
    }
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        setLoading: setIsLoading,
        isLoading
      }}
    >
      <div className="app-container">
        {isLoading && <Loader />}
        <Router history={history}>
          <Switch>
            <Route path="/" exact component={Homepage} />
            <ProtectedRoute path="/report_portal/" component={UserPortal} />
            <Route render={() => <h1>Error 404. Page not found.</h1>} />
          </Switch>
        </Router>
      </div>
    </UserInfoContext.Provider>
  );
}

export default App;
