/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { UserInfoContext } from '../../contexts';
import { post } from '../../utility/fetch';
import coatOfArm from '../../assets/images/coat-of-arm.png';
import Footer from '../layouts/Footer';

const Home = (props) => {
  const { setCurrentUser } = useContext(UserInfoContext);

  const makePostRequest = async () => {
    try {
      const emp = new URLSearchParams(props.location.search).get('emp');
      const { status, data } = await post(
        `/Authentication/AccessToken?Token=${emp}`
      );
      if (status === 'success') {
        sessionStorage.setItem('token', data.token);

        localStorage.setItem('USER_INFO', JSON.stringify(data));
        Cookies.set('homeLink', data.homeLink, { expires: 0.0416665 });
        Cookies.set('token', data.token, { expires: 0.0416665 });
        Cookies.set('tokenExist', true, { expires: 0.0416665 });

        setCurrentUser(data);
        props.history.push('/report_portal');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      alert('Error Loading Data');
    }
  };

  useEffect(() => {
    makePostRequest();
  }, []);

  return (
    <div className="w-100">
      <div className="banner">
        <div className="login">
          <div className="coat-of-arm">
            <img src={coatOfArm} alt="" />
            <h2>EDO STATE</h2>
            <h4>GOVERNMENT</h4>
          </div>
          <div className="form">Loading...</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
