import React, { useEffect, useState, useContext } from 'react';
import { get } from '../../utility/fetch';
import { UserInfoContext } from '../../contexts';
import useMdaData from '../../utility/useMda';
import { exportTableData } from '../../utility/exportToExcel';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import { formatTableHeaderToCamelCase } from '../../utility/general';

const ColumnHeaders = [
  'MDA Name',
  'Approved',
  'Awaiting Approval',
  'Declined',
  'Saved'
];

const MailManagerColumnHeaders = [
  'Mda Name',
  'Actioned',
  'Approved',
  'Assigned',
  'Communique',
  'Declined',
  'File Request',
  'Grand Total',
  'Keep In View',
  'Note Request',
  'Pending'
];
const managerTypes = [
  'General Request',
  'Facility Manager',
  'Grievance Reporting',
  'Leave Manager',
  'Mail Manager',
  'Memo Manager',
  'Training Manager',
  'Asset Management',
  'Individual Appraisal',
  'Legal Case',
  'School Manager',
  'Inventory Manager',
  'Budget',
  'Expense',
  'MDA Appraisal',
  'Treasury',
  'Requisition Manager',
];

let requestDataMemo;

const Mdas = ({ history }) => {
  const [title, setTitle] = useState('');
  const { setLoading } = useContext(UserInfoContext);
  const [managerType, setManagerType] = useState('General Request');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [selectedMda, setSelectedMda] = useState(null);
  const [requestData, setRequestData] = useState([]);
  const exportData = useFilteredData(requestData);

  const columnHeaders =
    managerType === 'Mail Manager' ? MailManagerColumnHeaders : ColumnHeaders;

  const formatSelectedHeaders = (type) => {
    const headerAlias = {
      GeneralRequest: 'GeneralRequest'
    };
    if (headerAlias[type]) return headerAlias[type];
    return type;
  };

  const getRequestData = async (useMda) => {
    try {
      setLoading(true);
      setRequestData([]);
      const type = managerType.split(' ').join('');
      const path = formatSelectedHeaders(type);
      const url = selectedMda
        ? `/Requests/${path}/${selectedMda.id}`
        : `/Requests/${path}`;
      const { status, data } = await get(url, {}, true);
      if (status === 'success') {
        if (!requestDataMemo) requestDataMemo = data;

        setRequestData(data);
      }
    } catch (error) {
      console.log(error);
      setRequestData([]);

    } finally {
      setLoading(false);
    }
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Requests';
    setTitle('Requests');
  };

  useEffect(() => {
    performPageMetaActions();
    getRequestData();
  }, []);

  useEffect(() => {
    getRequestData(true);
  }, [managerType, selectedMda]);

  const onInputChange = async (value) => {
    try {
      setLoading(true);
      if (value === 'All') {
        setSelectedMda(null);
        return setRequestData(requestDataMemo);
      }
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];
      setSelectedMda(mda);
      const type = managerType.split(' ').join('');
      const { status, data } = await get(
        `/Requests/${type}/${mda.id}`,
        {},
        true
      );
      if (status === 'success') {
        setRequestData(data);
      }
    } catch (error) {
      console.log(error);
      setRequestData([])
    } finally {
      setLoading(false);
    }
  };

  const onManagerTypeChange = async (value) => {
    setManagerType(value);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas]}
        />
        <SelectInput
          value={managerType}
          onChange={onManagerTypeChange}
          label="Select Module"
          options={[...managerTypes]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, columnHeaders, "Requests")}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {columnHeaders.map((header) => (
              <div className="th ">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-body">
          {exportData.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(columnHeaders).map((header) => (
                <div className="td left-text">{item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mdas;
