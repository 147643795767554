import React, { useEffect, useState } from 'react';
import { get } from './fetch';

const useMdaData = () => {
  const [mdaData, setMdaData] = useState([]);
  const getmdaData = async () => {
    try {
      const { status, data } = await get('/Mdas', {}, true);
      if (status === 'success') {
        setMdaData(data);
      }

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!mdaData.length) {
      getmdaData();
    }
  }, []);

  return mdaData;
};

export default useMdaData;
