import React, { useEffect, useState, useContext } from 'react';
import { get } from '../../utility/fetch';
import { UserInfoContext } from '../../contexts';
import useMdaData from '../../utility/useMda';
import useFilteredData from '../../hooks/useFilteredData';
import { exportTableData } from '../../utility/exportToExcel';
import SelectInput from '../inputs/SelectInput';
import {
  formatTableHeaderToCamelCase,
  formatTableNameFromCamelCase
} from '../../utility/general';

const ColumnHeaders = ['Approver', 'MdaName','Module', 'Status', ];

const managerTypes = [
  'General Request',
  'Facility Manager',
  'Grievance Reporting',
  'Leave Manager',
  'Mail Manager',
  'Memo Manager',
  'Training Manager',
  'Asset Management',
  'Individual Appraisal',
  'Legal Case',
  'School Manager',
  'Inventory Manager',
  'Budget',
  'Expense',
  'MDA Appraisal',
  'Treasury',
  'Requisition Manager',
];

const statusTypes = ['Pending', 'Accepted', 'Declined'];
let reportSummaryMemo;

const ReportSummary = ({ history }) => {
  const [title, setTitle] = useState('');
  const { setLoading } = useContext(UserInfoContext);
  const [managerType, setManagerType] = useState('General Request');
  const [mdaId, setMdaId] = useState(1);
  const [statusType, setStatusType] = useState('Pending');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [reportSummaryData, setReportSummary] = useState([]);
  const exportData = useFilteredData(reportSummaryData);

  const formatSelectedHeaders = (type) => {
    return type;
  };

  const getonBoardingStaffData = async () => {
    try {
      setLoading(true);
      setReportSummary([])
      const type = managerType.split(' ').join('');
      const { status, data } = await get(
        `/Requests/${formatSelectedHeaders(type)}/Details`,
        { status: statusType, MdaId: mdaId },
        true
      );
      if (status === 'success') {
        if (!reportSummaryMemo) reportSummaryMemo = data;
        setReportSummary(data);
      }
    } catch (error) {
      console.log(error);
      setReportSummary([])
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getonBoardingStaffData();
  }, [managerType, mdaId, statusType]);

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Report Details';
    setTitle('Report Details');
    getonBoardingStaffData();
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  const onInputChange = async (value) => {
    try {
      setLoading(true);
      if (value === 'All') return setReportSummary(reportSummaryMemo);
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];
      setMdaId(mda.id);
      // const { status, data } = await get(`/Report/Summary/${mda.id}`, {}, true);
      // if (status === 'success') {
      //   setReportSummary(data);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onManagerTypeChange = async (value) => {
    setManagerType(value);
    console.log(value,managerType)
  };

  const onStatusTypeChange = async (value) => {
    setStatusType(value);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas]}
        />
        <SelectInput
          value={managerType}
          onChange={onManagerTypeChange}
          label="Select Module"
          options={[...managerTypes]}
        />
        <SelectInput
          value={statusType}
          onChange={onStatusTypeChange}
          label="Select Status"
          options={[...statusTypes]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders, "Request Details")}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {ColumnHeaders.map((header) => (
              <div className="th ">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        
        <div className="table-body">
          {exportData?.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => (
                <div className="td left-text"> {header === 'module' ? managerType : item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportSummary;
