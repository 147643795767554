import React from 'react';
import './style.css';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
  );
};

export default Loader;
