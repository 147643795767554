/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectInput = (props) => {
  const options =
    props.options &&
    props.options.map((option) =>
      typeof option === 'object' ? (
        <Option
          value={
            option.value &&
            option.value.toString().toLowerCase() === 'select item...'
              ? ''
              : option.value
          }
          key={option.name}
        >
          {option.name}
        </Option>
      ) : (
        <Option
          value={
            option && option.toString().toLowerCase() === 'select item...'
              ? ''
              : option
          }
          key={option}
        >
          {option}
        </Option>
      )
    );
  return (
    <div>
      <div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>

      <Select
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        showSearch={true}
        style={{ minWidth: '20rem' }}
        // onSearch={onSearch}
        placeholder="search"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </div>
  );
};

export default SelectInput;
