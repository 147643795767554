import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../components/pages/ModuleUsage';
import MdaSetup from '../components/pages/MdaReport';
import Requests from '../components/pages/Requests';
import RequestDetails from '../components/pages/RequestDetails';
import StaffReport from '../components/pages/StaffReport';
import ReportSummary from '../components/pages/ReportSummary';
import OnboardedStaff from '../components/pages/OnboardedStaff';
import Approvals from '../components/pages/Approvals';

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/report_portal/" exact component={Dashboard} />
    <ProtectedRoute path="/report_portal/mdas" exact component={MdaSetup} />
    <ProtectedRoute
      path="/report_portal/onboarded_staff"
      exact
      component={OnboardedStaff}
    />
    <ProtectedRoute path="/report_portal/staff" exact component={StaffReport} />
    <ProtectedRoute path="/report_portal/requests" exact component={Requests} />
    <ProtectedRoute
      path="/report_portal/report_details"
      exact
      component={RequestDetails}
    />
    <ProtectedRoute
      path="/report_portal/report"
      exact
      component={ReportSummary}
    />
    <ProtectedRoute
      path="/report_portal/approvals"
      exact
      component={Approvals}
    />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
