export const toggleScroll = () => {
  const overlays = document.querySelectorAll('.overlay');

  if (overlays.length > 0) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return '';
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const formatTableNameFromCamelCase = (camelCaseString) => {
  if (Array.isArray(camelCaseString)) {
    return camelCaseString.map((s) => formatTableNameFromCamelCase(s));
  }
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const formatTableHeaderToCamelCase = (headers) => {
  if (Array.isArray(headers)) {
    return headers.map((s) => formatTableHeaderToCamelCase(s));
  }
  return headers
    .split(' ')
    .map((header, index) => {
      const smallCaseTerms = ['at', 'on', 'of', 'to'];
      if (index === 0 || smallCaseTerms.includes(header.toLowerCase()))
        return header.toLowerCase();
      return header.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
    })
    .join('');
};

export const formatFileUrl = (path) =>
  `${process.env.REACT_APP_BACKEND_URL}/${path}`;
