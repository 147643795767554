import React, { useEffect, useState, useContext, useMemo } from 'react';
import { get } from '../../utility/fetch';
import useFilteredData from '../../hooks/useFilteredData';
import { exportTableData } from '../../utility/exportToExcel';
import { UserInfoContext } from '../../contexts';
import useMdaData from '../../utility/useMda';
import SelectInput from '../inputs/SelectInput';
import { formatTableHeaderToCamelCase } from '../../utility/general';

const ColumnHeaders = ['Name', 'Date Established', 'Location', 'Mandate'];
const Mdas = ({ history }) => {
  const { setLoading } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [mdaReports, setMdaReports] = useState(mdas);
  const exportData = useFilteredData(mdaReports, 'name');

  useEffect(() => {
    setMdaReports(mdas);
  }, [mdas]);

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Module Usage';
    setTitle('Module Usage');
  };

  useEffect(() => {
    performPageMetaActions();
  }, []);

  const onMdaInputChange = async (value) => {
    try {
      if (value === 'All') return setMdaReports(mdas);
      setLoading(true);
      const mda = mdaReports.filter((m) => m.name === value)[0];
      const { status, data } = await get(`/Mdas/${mda.id}`, {}, true);
      setMdaReports(data);
    } catch (error) {
      console.log(error);
      setMdaReports([])
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onMdaInputChange}
          label="Select MDA"
          options={['All', ...mdas.map((mda) => mda.name)]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders, "Mda Report")}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {ColumnHeaders.map((header) => (
              <div className="th">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-body">
          {exportData?.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => (
                <div className="td left-text">{item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mdas;
