import React, { useEffect, useState, useContext, useMemo } from 'react';
import { get } from '../../utility/fetch';
import { exportTableData } from '../../utility/exportToExcel';
import useFilteredData from '../../hooks/useFilteredData';
import { UserInfoContext } from '../../contexts';
import useMdaData from '../../utility/useMda';
import SelectInput from '../inputs/SelectInput';
import { formatTableHeaderToCamelCase } from '../../utility/general';

const ColumnHeaders = ['MDA Name', 'Approved', 'Declined', 'Pending'];

const managerTypes = [
  'General Request',
  'Facility Manager',
  'Grievance Reporting',
  'Leave Manager',
  'Mail Manager',
  'Memo Manager',
  'Training Manager',
  'Asset Management',
  'Individual Appraisal',
  'Legal Case',
  'School Manager',
  'Inventory Manager',
  'Budget',
  'Expense',
  'MDA Appraisal',
  'Treasury',
  'Requisition Manager',
];

let approvalDataMemo;

const Approvals = () => {
  const { currentUser, setLoading } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  const [selectedMda, setSelectedMda] = useState(null);
  const [managerType, setManagerType] = useState('GeneralRequest');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [approvalData, setApprovalData] = useState([]);
  const exportData = useFilteredData(approvalData);


  const fetchPageData = async () => {
    try {
      setLoading(true);
      setApprovalData([])
      const path = managerType.split(' ').join('');
      const url = selectedMda
        ? `/Approvals/${path}/${selectedMda.id}`
        : `/Approvals/${path}`;
      const { status, data } = await get(url, {}, true);
      console.log(data)

      if (status === 'success' && data) {
        if (!approvalDataMemo) approvalDataMemo = data;
        setApprovalData(data);
      }
    } catch (error) {
      console.log(error);
      setApprovalData([])
    } finally {
      setLoading(false);
    }
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Approvals';
    setTitle('Approvals Analytics');
  };

  useEffect(() => {
    fetchPageData();
    performPageMetaActions();
  }, []);

  useEffect(() => {
    fetchPageData();
  }, [managerType, selectedMda]);

  const onInputChange = async (value) => {
    try {
      setLoading(true);
      
      if (value === 'All') {
        setSelectedMda(null);
        return setApprovalData(approvalDataMemo);
      }

      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];

      setSelectedMda(mda);

      const { status, data } = await get(
        `/Approvals/${managerType.split(' ').join('')}/${mda.id}`,
        {},
        true
      );
      if (status === 'success') {
        console.log(data);
        setApprovalData(data);
      }
    } catch (error) {
      console.log(error);
      setApprovalData([])
    } finally {
      setLoading(false);
    }
  };

  const onManagerTypeChange = async (value) => {
    setManagerType(value);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas]}
        />
        <SelectInput
          value={managerType}
          onChange={onManagerTypeChange}
          label="Select Module Type"
          options={[...managerTypes]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders, 'Approvals Analytics' )}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {ColumnHeaders.map((header) => (
              <div className="th ">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-body">
          {exportData.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => (
                <div className="td left-text">{item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Approvals;
