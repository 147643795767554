import React, { useEffect, useState, useContext } from 'react';
import { get } from '../../utility/fetch';
import { UserInfoContext } from '../../contexts';
import { exportTableData } from '../../utility/exportToExcel';
import useFilteredData from '../../hooks/useFilteredData';
import useMdaData from '../../utility/useMda';
import SelectInput from '../inputs/SelectInput';
import { formatTableHeaderToCamelCase } from '../../utility/general';

// const ColumnHeaders = [
//   'MDA Name',
//   'Mail Manager',
//   'Memo Manager',
//   'Leave Manager',
//   'General Request',
//   'Training Manager',
//   'Grievance Reporting',
//   'Facility Manager',
//   'Inventory Manager',
//   'MDA Appraisal',
//   'Legal Case',
//   'School Manager',
//   'Budget',
//   'Expense',
//   'Asset Management'
// ];

const ColumnHeaders = [
  'MDA Name',
  'General Request',
  'Facility Manager',
  'Grievance Reporting',
  'Leave Manager',
  'Mail Manager',
  'Memo Manager',
  'Training Manager',
  'Asset Management',
  'Individual Appraisal',
  'Legal Case',
  'School Manager',
  'Inventory Manager',
  'Budget',
  'Expense',
  'MDA Appraisal',
  'Treasury',
  'Requisition Manager',
];

let moduleUsageMemo;
const Dashboard = ({ history }) => {
  const [title, setTitle] = useState('');
  const { setLoading } = useContext(UserInfoContext);
  const [moduleUsage, setModuleUsage] = useState([]);
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const exportData = useFilteredData(moduleUsage);

  const getModuleUsage = async () => {
    try {
      setLoading(true);
      const { status, data } = await get('/ModuleUsage', {}, true);
      if (status === 'success') {
        moduleUsageMemo = data;
        setModuleUsage(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Module Usage';
    setTitle('Module Usage');
    getModuleUsage();
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  const onInputChange = async (value) => {
    try {
      setLoading(true);
      if (value === 'All') return setModuleUsage(moduleUsageMemo);
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];
      const { status, data } = await get(`/ModuleUsage/${mda.id}`, {}, true);
      setModuleUsage(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-50 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas.map((mda) => mda.name)]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders , "Module Usage")}
        >
          Export To Excel
        </button>
      </div>
      <div className="w-100 overflow-wrapper-x flex">
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              {ColumnHeaders.map((header) => (
                <div className="th">
                  <p className="san-sherif">{header}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="table-body">
            {exportData.map((item) => (
              <div className="table-row" key={item.id}>
                {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => {
                  return ['yes', 'no'].includes(item[header]?.toLowerCase()) ? (
                    <div className="td">
                      <input
                        type="checkbox"
                        checked={item[header].toLowerCase() === 'yes'}
                        name="checkbox"
                      />
                    </div>
                  ) : (
                    <div className="td left-text">{item[header]}</div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
